import React, { useState, useEffect, useCallback } from "react";
import { saveAs } from "file-saver";
import {
  FaCalendarAlt,
  FaClock,
  FaClipboardList,
  FaPlus,
  FaUsers,
  FaHourglassHalf,
  FaArrowLeft,
  FaShoppingCart,
  FaInfoCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import BaseSelect from "react-select";
import DatePicker from "react-datepicker";
import FixRequiredSelect from "./FixRequiredSelect";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/DatePicker.css";
import { db } from "../firebase";
import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import emailjs from "@emailjs/browser";
import { IoMail } from "react-icons/io5";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";

const AddBooking = () => {
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState("");
  const [bookingPreview, setBookingPreview] = useState("");
  const [details, setDetails] = useState("");
  const [selectedUsers, setSelectedUsers] = useState("");
  const [selectedCart, setSelectedCart] = useState("");
  const [availableCarts, setAvailableCarts] = useState([]);
  const [isThursday, setIsThursday] = useState(false);
  const [isWeekend, setIsWeekend] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [bookingDetails, setBookingDetails] = useState(null);
  const [reservedBy, setReservedBy] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  
  registerLocale("sv", sv);
  setDefaultLocale("sv");
  
  const getCartName = (cart) => {
    return cart === "1" ? "Vagn 1&2" : "Vagn 3&4";
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const Select = (props) => (
    <FixRequiredSelect {...props} SelectComponent={BaseSelect} />
  );

  useEffect(() => {
    if (date && startTime) {
      const formattedDate = formatDate(date);
      const [year, month, day] = formattedDate.split("-");
      const [hours, minutes] = startTime.split(":");
      const startDate = new Date(year, month - 1, day, hours, minutes);
      const endDate = new Date(startDate.getTime() + duration * 60 * 60 * 1000);

      const formatTime = (date) => date.toTimeString().slice(0, 5);

      setBookingPreview(
        `${formattedDate} ${formatTime(startDate)}-${formatTime(endDate)}`
      );
    }
  }, [date, startTime, duration]);

  useEffect(() => {
    const dayOfWeek = new Date(date).getDay();
    const isThursdayNow = dayOfWeek === 4;
    const isWeekendNow = dayOfWeek === 0 || dayOfWeek === 6;
    setIsThursday(isThursdayNow);
    setIsWeekend(isWeekendNow);

    if (isThursdayNow) {
      setInfoMessage(
        "På torsdagar är det vagndag i Vasaparken, vilket innebär att vi har tillstånd för två vagnar mellan 8-18. Vagn 3&4 är reserverade för möte för tjänst 11:30. Vid frågor kontakta Rickard eller Meliza."
      );
      setSelectedCart("");
      setDetails("Vasaparken");
    } else if (isWeekendNow) {
      setInfoMessage(
        "Vi har tillstånd för två vagnar i Vasaparken mellan 10-16. Övrig tid och plats flyttar vi vagnarna efter en kort stund. Vid frågor kontakta Rickard eller Meliza."
      );
    } else {
      setInfoMessage(
        "Vi har tillstånd för två vagnar i Vasaparken mellan 8-18. Övrig tid och plats flyttar vi vagnarna efter en kort stund. Vid frågor kontakta Rickard eller Meliza."
      );
      setSelectedCart("");
      setDetails("");
    }
  }, [date]);

  const fetchUnavailableCarts = useCallback(
    async (selectedDate, selectedStartTime, selectedDuration) => {
      if (!selectedDate || !selectedStartTime || !selectedDuration)
        return new Set();

      const dateString = selectedDate.toLocaleString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      console.log("Fetching unavailable carts for:", dateString);

      const bookingsQuery = query(
        collection(db, "booking"),
        where("dateString", "==", dateString)
      );

      const querySnapshot = await getDocs(bookingsQuery);
      const unavailableCarts = new Set();

      const [bookingStartHours, bookingStartMinutes] = selectedStartTime
        .split(":")
        .map(Number);
      const bookingStartTime = new Date(
        0,
        0,
        0,
        bookingStartHours,
        bookingStartMinutes
      );
      const bookingEndTime = new Date(
        bookingStartTime.getTime() + selectedDuration * 60 * 60 * 1000
      );

      querySnapshot.forEach((doc) => {
        const booking = doc.data();
        const [bookingHours, bookingMinutes] = booking.starttime
          .split(":")
          .map(Number);
        const existingBookingStart = new Date(
          0,
          0,
          0,
          bookingHours,
          bookingMinutes
        );
        const existingBookingEnd = new Date(
          existingBookingStart.getTime() + booking.duration * 60 * 60 * 1000
        );

        if (
          (bookingStartTime < existingBookingEnd &&
            bookingEndTime > existingBookingStart) ||
          (existingBookingStart < bookingEndTime &&
            existingBookingEnd > bookingStartTime)
        ) {
          unavailableCarts.add(booking.cart);
        }
      });

      return unavailableCarts;
    },
    []
  );
  useEffect(() => {
    const updateAvailableCarts = async () => {
      if (date && startTime && duration) {
        const unavailableCarts = await fetchUnavailableCarts(
          date,
          startTime,
          duration
        );

        if (isThursday) {
          setAvailableCarts(
            [{ id: "1", name: "Vagn 1 & 2" }]
              .filter((cart) => !unavailableCarts.has(cart.id))
              .map((cart) => ({ label: cart.name, value: cart.id }))
          );
        } else {
          setAvailableCarts(
            [
              { id: "1", name: "Vagn 1 & 2" },
              { id: "2", name: "Vagn 3 & 4" },
            ]
              .filter((cart) => !unavailableCarts.has(cart.id))
              .map((cart) => ({ label: cart.name, value: cart.id }))
          );
        }
      }
    };

    updateAvailableCarts();
  }, [date, startTime, duration, isThursday, fetchUnavailableCarts]);

  const generateIcsFile = (
    bookingDate,
    startTime,
    duration,
    details,
    selectedCart
  ) => {
    const [year, month, day] = bookingDate.split("-");
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const startDate = new Date(year, month - 1, day, startHours, startMinutes);
    const endDate = new Date(startDate.getTime() + duration * 60 * 60 * 1000);

    const formatDateTime = (date) => {
      return date.toISOString().replace(/-|:|\.\d+/g, "");
    };

    const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:Bokning för ${getCartName(selectedCart)}
DTSTART:${formatDateTime(startDate)}
DTEND:${formatDateTime(endDate)}
DESCRIPTION:${details}
LOCATION:${details}
END:VEVENT
END:VCALENDAR
    `.trim();

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    saveAs(blob, `bokning-${selectedCart}.ics`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!date || !startTime) {
      console.error("Date or start time is missing");
      return;
    }

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_CONFIRM_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    const templateParams = {
      starttime: startTime,
      duration: duration,
      date: date.toLocaleString("sv-SE", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }),
      persons: selectedUsers,
      selectedcart: getCartName(selectedCart),
      location: details,
      reserved_by: reservedBy,
    };

    // Convert date to a string in the format "yyyy-MM-dd"
    const formattedDate = formatDate(date);

    // Convert date and startTime to a Date object
    const [year, month, day] = formattedDate.split("-");
    const [hours, minutes] = startTime.split(":");
    const bookingDate = new Date(year, month - 1, day, hours, minutes);

    // Format the date to "2 November 2024 at 08:21:32 UTC+1"
    const formattedBookingDate = bookingDate.toLocaleString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });

    const dateString = date.toLocaleString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    try {
      await addDoc(collection(db, "booking"), {
        cart: selectedCart,
        date: formattedBookingDate,
        dateString: dateString,
        duration: Number(duration),
        location: details,
        persons: selectedUsers,
        starttime: startTime,
      });

      // Store booking details before clearing the form
      setBookingDetails({
        date: formattedDate,
        startTime,
        duration,
        details,
        selectedCart,
      });

      emailjs
        .send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
          console.log(
            "Email sent successfully:",
            response.status,
            response.text
          );
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });

      // Clear the form
      setDate(new Date());
      setStartTime("");
      setDuration("");
      setDetails("");
      setSelectedUsers("");
      setSelectedCart("");
      setConfirmationMessage(
        `${getCartName(selectedCart)} bokad ${bookingPreview}. Bekräftelsemail skickas inom kort.`
      );
      setBookingPreview("");
      setReservedBy("");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleAddToCalendarClick = () => {
    if (bookingDetails) {
      const { date, startTime, duration, details, selectedCart } =
        bookingDetails;
      generateIcsFile(date, startTime, duration, details, selectedCart);
    } else {
      console.error("No booking details available for calendar event");
    }
  };

  const generateTimeOptions = () => {
    const options = [];
    const startHour = isWeekend ? 10 : 8;
    const endHour = isWeekend ? 15 : 17;

    for (let i = startHour; i <= endHour; i++) {
      for (let j = 0; j < 60; j += 30) {
        const time = `${i.toString().padStart(2, "0")}:${j
          .toString()
          .padStart(2, "0")}`;
        options.push({ label: time, value: time });
      }
    }
    return options;
  };

  const generateDurationOptions = () => {
    const options = [];
    if (startTime) {
      const [hours, minutes] = startTime.split(":").map(Number);
      const startDate = new Date(0, 0, 0, hours, minutes);
      const endLimit = new Date(0, 0, 0, isWeekend ? 16 : 18, 0);
      const maxDuration = (endLimit - startDate) / (1000 * 60 * 60);

      for (let i = 0.5; i <= Math.min(2, maxDuration); i += 0.5) {
        options.push({ label: `${i} timmar`, value: i });
      }
    }
    return options;
  };

  const generateThursdayTimeSlots = () => {
    return [
      "08:00-10:00",
      "10:00-11:30",
      "11:30-12:30",
      "12:30-13:30",
      "13:30-14:30",
      "14:30-15:30",
    ].map((timeSlot) => ({ label: timeSlot, value: timeSlot }));
  };

  const handleTimeSlotChange = (option) => {
    const [start, end] = option.value.split("-");
    setStartTime(start);

    const [startHours, startMinutes] = start.split(":").map(Number);
    const [endHours, endMinutes] = end.split(":").map(Number);

    const startDate = new Date(0, 0, 0, startHours, startMinutes);
    const endDate = new Date(0, 0, 0, endHours, endMinutes);

    const durationInHours = (endDate - startDate) / (1000 * 60 * 60);
    setDuration(durationInHours);
  };

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const startDate = new Date(0, 0, 0, startHours, startMinutes);
    const endDate = new Date(startDate.getTime() + duration * 60 * 60 * 1000);
    return `${endDate.getHours().toString().padStart(2, "0")}:${endDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  // Calculate the maximum date for booking (2 weeks from today)
  const maxBookingDate = new Date();
  maxBookingDate.setDate(maxBookingDate.getDate() + 14);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-green-400 flex justify-center items-center p-4 sm:p-6">
      <div className="bg-white rounded-lg shadow-xl p-6 sm:p-8 max-w-md w-full">
        <Link to="/" className="block mb-4">
          <button className="flex items-center text-blue-grotto hover:text-navy-blue transition duration-300">
            <FaArrowLeft className="mr-2" />
            Tillbaka Till Startsida
          </button>
        </Link>

        <h2 className="text-navy-blue text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center flex items-center justify-center">
          <FaPlus className="mr-2 sm:mr-3" />
          Lägg till Bokning
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
          <div className="relative">
            <label className="text-sm font-medium text-gray-700 mb-1 flex items-center">
              <FaCalendarAlt className="mr-2" />
              Välj Datum
            </label>
            <DatePicker
              selected={date}
              placeholderText="Välj datum"
              onChange={(date) => setDate(date)}
              className="border border-gray-300 p-3 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-outline"
              dateFormat="yyyy/dd/MM"
              maxDate={maxBookingDate}
              minDate={new Date()}
              required
              locale="sv"
              showWeekNumbers
              weekLabel="V"
            />
          </div>

          {infoMessage && (
            <div className="border border-gray-700 rounded-md p-2 mb-4">
              <label className="text-sm font-medium text-gray-700 mb-1 flex items-center">
                <FaInfoCircle className="mr-2" />
                Info
              </label>
              <div className="text-sm text-gray-700 mt-2 flex items-center">
                {infoMessage}
              </div>
            </div>
          )}

          {isThursday ? (
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 mb-1 flex items-center">
                <FaClock className="mr-2" />
                Bokningstid
              </label>
              <Select
                value={generateThursdayTimeSlots().find(
                  (option) =>
                    option.value ===
                    `${startTime}-${calculateEndTime(startTime, duration)}`
                )}
                onChange={handleTimeSlotChange}
                options={generateThursdayTimeSlots()}
                placeholder="Välj tid"
                required
              />
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <div className="flex-1 mb-4 sm:mb-0">
                <label className="text-sm font-medium text-gray-700 mb-1 flex items-center">
                  <FaClock className="mr-2" />
                  Starttid
                </label>
                <Select
                  value={generateTimeOptions().find(
                    (option) => option.value === startTime
                  )}
                  onChange={(option) => setStartTime(option.value)}
                  options={generateTimeOptions()}
                  placeholder="Välj tid"
                  required
                />
              </div>
              <div className="flex-1">
                <label className="text-sm font-medium text-gray-700 mb-1 flex items-center">
                  <FaHourglassHalf className="mr-2" />
                  Varaktighet (timmar)
                </label>
                <Select
                  value={generateDurationOptions().find(
                    (option) => option.value === duration
                  )}
                  onChange={(option) => setDuration(option.value)}
                  options={generateDurationOptions()}
                  placeholder="Välj varaktighet"
                  required
                />
              </div>
            </div>
          )}

          {bookingPreview && (
            <div className="text-sm text-gray-600 mt-2">
              Bokningstid: {bookingPreview}
            </div>
          )}
          <div className="relative">
            <label className="text-sm font-medium text-gray-700 mb-1 flex items-center">
              <FaUsers className="mr-2" />
              Personer
            </label>
            <input
              type="text"
              value={selectedUsers}
              onChange={(e) => setSelectedUsers(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-outline"
              placeholder="Ange personer"
              required
            />
          </div>

          <div className="relative">
            <label className="text-sm font-medium text-gray-700 mb-1 flex items-center">
              <FaShoppingCart className="mr-2" />
              Välj Vagn
            </label>
            <Select
              value={availableCarts.find(
                (option) => option.value === selectedCart
              )}
              onChange={(option) => setSelectedCart(option.value)}
              options={availableCarts}
              placeholder="Välj en vagn"
              required
              noOptionsMessage={() => "Inga vagnar tillgängliga..."}
            />
          </div>

          <div className="relative">
            <span className="absolute left-3 top-3 text-gray-400">
              <FaClipboardList />
            </span>
            <textarea
              placeholder="Plats / Övrig bokningsinformation"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              className="border border-gray-300 p-2 pl-10 rounded-md w-full h-32 focus:outline-none focus:ring-2 focus:ring-blue-outline placeholder-gray-400"
              required
            />
          </div>

          <div className="relative">
            <label className="text-sm font-medium text-gray-700 mb-1 flex items-center">
              <IoMail className="mr-2" />
              Ange e-post för bekräftelse (valfritt)
            </label>
            <input
              type="text"
              value={reservedBy}
              onChange={(e) => setReservedBy(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-outline"
              placeholder="Ange e-post"
            />
          </div>

          <div className="relative">
            <label className="flex items-center text-sm font-medium text-gray-700">
              <input
                type="checkbox"
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
                required
                className="form-checkbox h-7 w-7 text-blue-grotto transition duration-150 ease-in-out mr-2 accent-blue-grotto"
              />
              <span className="ml-2">
                Jag bekräftar att alla som är med på bokningen har genomgått
                utbildning för vagntjänst.
              </span>
            </label>
          </div>

          <button
            type="submit"
            className="bg-blue-grotto hover:bg-navy-blue text-white p-3 rounded-md w-full transition duration-300 ease-in-out flex items-center justify-center"
          >
            <FaPlus className="mr-2" />
            <span>Lägg Till Bokning</span>
          </button>
        </form>

        {confirmationMessage && (
          <div className="mt-4 text-navy-blue text-center">
            {confirmationMessage}
            <div>
              <button
                onClick={handleAddToCalendarClick}
                className="text-blue-500 underline"
              >
                Lägg till i kalender
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddBooking;
